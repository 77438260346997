import React, { useState } from "react";
import * as Realm from "realm-web";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Helmet } from "react-helmet";

export default function ProjectForm() {
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    phoneNumber: "",
    email: "",
    companyName: "",
    digitalScope: "",
    projectObjective: "",
    primaryChallenges: "",
    budgetAllocation: "",
    brandBook: "No",
    uiUx: "No",
    communication: "",
    additionalInfo: "No",
    additionalInfoText: "",
    methodology: "",
    integration: "",
    compliance: "No",
    security: "",
    scalability: "",
    duration: "",
    deploy: "No",
  });

  const [isSubmitting, setIsSubmitting] = useState(false);

  // Handle input changes
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true); // Disable submit button

    try {
      const app = new Realm.App({ id: "application-0-dlhopxm" });
      const credentials = Realm.Credentials.anonymous();
      const user = await app.logIn(credentials);
      const mongo = user.mongoClient("mongodb-atlas");
      const collection = mongo.db("bharatsoft").collection("contactform");

      const formDataToSend = {
        ...formData,
        createdAt: new Date(),
      };

      await collection.insertOne(formDataToSend);
      toast.success("Data submitted successfully!");

      setTimeout(() => {
        window.location.reload(); // Reload page after 1 second
      }, 1000);
    } catch (error) {
      console.error("Error submitting data:", error);
      toast.error("Failed to submit data.");
    } finally {
      setIsSubmitting(false); // Re-enable submit button
    }
  };

  return (
    <>
      <Helmet>
        <title>Project Details Form</title>
        <meta name="description" content="Project Form" />
      </Helmet>
      <div className="container mt-5">
        <h2 className="text-center">Project Details Form</h2>
        <form onSubmit={handleSubmit} className="mt-4 mb-5">
          <div className="row">
            <div className="col-md-6">
              <div className="mb-3">
                <label className="form-label">First Name</label>
                <input
                  type="text"
                  className="form-control"
                  name="firstName"
                  value={formData.firstName}
                  onChange={handleInputChange}
                  placeholder="Enter First Name"
                />
              </div>
              <div className="mb-3">
                <label className="form-label">Phone Number</label>
                <input
                  type="text"
                  className="form-control"
                  name="phoneNumber"
                  value={formData.phoneNumber}
                  onChange={handleInputChange}
                  placeholder="Enter Phone Number"
                />
              </div>
              <div className="mb-3">
                <label className="form-label">Company Name</label>
                <input
                  type="text"
                  className="form-control"
                  name="companyName"
                  value={formData.companyName}
                  onChange={handleInputChange}
                  placeholder="Enter Company Name"
                />
              </div>
              <div className="mb-3">
                <label className="form-label">
                  What is your Digital Scope?
                </label>
                <select
                  className="form-control"
                  name="digitalScope"
                  value={formData.digitalScope}
                  onChange={handleInputChange}
                  placeholder="Select Digital Scope"
                >
                  <option value="">Select Option</option>
                  <option value="Custom Software">Custom Software</option>
                  <option value="Design">Design</option>
                  <option value="Mobile App">Mobile App</option>
                  <option value="Staff Augmentation">Staff Augmentation</option>
                  <option value="Website">Website</option>
                </select>
              </div>
              <div className="mb-3">
                <label className="form-label">
                  What is your Budget Allocation?
                </label>
                <select
                  className="form-control"
                  name="budgetAllocation"
                  value={formData.budgetAllocation}
                  onChange={handleInputChange}
                >
                  <option value="">Select Option</option>
                  <option value="Less than € 10,000">Less than € 10,000</option>
                  <option value="€ 10,000 - € 50,000">
                    € 10,000 - € 50,000
                  </option>
                  <option value="€ 50,000 - € 100,000">
                    € 50,000 - € 100,000
                  </option>
                  <option value="Over € 100,000">Over € 100,000</option>
                </select>
              </div>
              <div className="mb-3">
                <label className="form-label">Existence of UI/UX Design?</label>
                <select
                  className="form-control"
                  name="uiUx"
                  value={formData.uiUx}
                  onChange={handleInputChange}
                >
                  <option value="">Select Option</option>
                  <option value="Yes">Yes</option>
                  <option value="No">No</option>
                </select>
              </div>
              <div className="mb-3">
                <label className="form-label">
                  Any Additional Information to Share?
                </label>
                <select
                  className="form-control"
                  name="additionalInfo"
                  value={formData.additionalInfo}
                  onChange={handleInputChange}
                >
                  <option value="">Select Option</option>
                  <option value="No">No</option>
                  <option value="Yes">Yes</option>
                </select>
              </div>
              {formData.additionalInfo === "Yes" && (
                <div className="mb-3">
                  <label className="form-label">Share Additional Info</label>
                  <textarea
                    className="form-control"
                    name="additionalInfoText"
                    value={formData.additionalInfoText}
                    onChange={handleInputChange}
                    placeholder="Share additional information"
                  />
                </div>
              )}
            </div>

            <div className="col-md-6">
              <div className="mb-3">
                <label className="form-label">Last Name</label>
                <input
                  type="text"
                  className="form-control"
                  name="lastName"
                  value={formData.lastName}
                  onChange={handleInputChange}
                  placeholder="Enter Last Name"
                />
              </div>
              <div className="mb-3">
                <label className="form-label">Email</label>
                <input
                  type="email"
                  className="form-control"
                  name="email"
                  value={formData.email}
                  onChange={handleInputChange}
                  placeholder="Enter Email"
                />
              </div>
              <div className="mb-3">
                <label className="form-label">
                  What is your Project's Objective?
                </label>
                <select
                  className="form-control"
                  name="projectObjective"
                  value={formData.projectObjective}
                  onChange={handleInputChange}
                >
                  <option value="">Select Option</option>
                  <option value="Enhance Business Efficiency">
                    Enhance Business Efficiency
                  </option>
                  <option value="Launch a New Product">
                    Launch a New Product
                  </option>
                  <option value="Improve the user experience">
                    Improve the user experience
                  </option>
                  <option value="Others">Others</option>
                </select>
              </div>
              <div className="mb-3">
                <label className="form-label">
                  What is your Primary Challenge?
                </label>
                <select
                  className="form-control"
                  name="primaryChallenges"
                  value={formData.primaryChallenges}
                  onChange={handleInputChange}
                >
                  <option value="">Select Option</option>
                  <option value="Workflow Optimization">
                    Workflow Optimization
                  </option>
                  <option value="Innovation">Innovation</option>
                  <option value="User Engagement">User Engagement</option>
                  <option value="Others">Others</option>
                </select>
              </div>
              <div className="mb-3">
                <label className="form-label">
                  What is your Preferred Communication Mode?
                </label>
                <select
                  className="form-control"
                  name="communication"
                  value={formData.communication}
                  onChange={handleInputChange}
                >
                  <option value="">Select Option</option>
                  <option value="Let suppliers initiate contact">
                    Let suppliers initiate contact.
                  </option>
                  <option value="Share supplier contacts; I'll initiate contact">
                    Share supplier contacts; I'll initiate contact.
                  </option>
                </select>
              </div>
              <div className="mb-3">
                <label className="form-label">
                  What is your Preferred Development Methodology?
                </label>
                <select
                  className="form-control"
                  name="methodology"
                  value={formData.methodology}
                  onChange={handleInputChange}
                >
                  <option value="">Select Option</option>
                  <option value="Agile">Agile</option>
                  <option value="Waterfall">Waterfall</option>
                  <option value="Others">Others</option>
                </select>
              </div>
              <div className="mb-3">
                <label className="form-label">
                  Your Integration Requirements?
                </label>
                <select
                  className="form-control"
                  name="integration"
                  value={formData.integration}
                  onChange={handleInputChange}
                >
                  <option value="">Select Option</option>
                  <option value="Specific systems or platforms for integration">
                    Specific systems or platforms for integration
                  </option>
                  <option value="No specific requirements">
                    No specific requirements
                  </option>
                </select>
              </div>
              <div className="mb-3">
                <label className="form-label">Regulatory Compliance?</label>
                <select
                  className="form-control"
                  name="compliance"
                  value={formData.compliance}
                  onChange={handleInputChange}
                >
                  <option value="">Select Option</option>
                  <option value="No">No</option>
                  <option value="Yes">Yes</option>
                </select>
              </div>
              <div className="mb-3">
                <label className="form-label">
                  Your Security Considerations?
                </label>
                <select
                  className="form-control"
                  name="security"
                  value={formData.security}
                  onChange={handleInputChange}
                >
                  <option value="">Select Option</option>
                  <option value="Low">Low</option>
                  <option value="Moderate">Moderate</option>
                  <option value="High">High</option>
                </select>
              </div>
              <div className="mb-3">
                <label className="form-label">
                  What is your Scalability Requirements?
                </label>
                <select
                  className="form-control"
                  name="scalability"
                  value={formData.scalability}
                  onChange={handleInputChange}
                >
                  <option value="">Select Option</option>
                  <option value="Low">Low</option>
                  <option value="Moderate">Moderate</option>
                  <option value="High">High</option>
                </select>
              </div>
              <div className="mb-3">
                <label className="form-label">
                  What is your Project Duration?
                </label>
                <select
                  className="form-control"
                  name="duration"
                  value={formData.duration}
                  onChange={handleInputChange}
                >
                  <option value="">Select Option</option>
                  <option value="3 Months">3 Months</option>
                  <option value="6 Months">6 Months</option>
                  <option value="Within 1 Year">Within 1 Year</option>
                </select>
              </div>
              <div className="mb-3">
                <label className="form-label">
                  Do you need Post-Deployment Support?
                </label>
                <select
                  className="form-control"
                  name="deploy"
                  value={formData.deploy}
                  onChange={handleInputChange}
                >
                  <option value="">Select Option</option>
                  <option value="No">No</option>
                  <option value="Yes">Yes</option>
                </select>
              </div>
            </div>
          </div>
          <button
            type="submit"
            className="btn btn-primary w-100 p-3"
            style={{ backgroundColor: "#14254C" }}
            // disabled={isSubmitting}
          >
            {isSubmitting ? "Submitting..." : "Submit Details"}
          </button>
        </form>
        <ToastContainer />
      </div>
    </>
  );
}
