import React, { useState } from "react";
import PageBanner from "../Components/PageBanner";
import { Helmet } from "react-helmet";
import BannerImg from "../Components/Images/CompanyRegistrationBG.jpg";

export default function CompanyRegistration() {
  const [formData, setFormData] = useState({
    companyName: "",
    avgProjectSize: "",
    projectCompleted: "",
    companySize: "",
    visitWebsite: "",
    location: "",
    aboutCompany: "",
    remoteCollaborationPossible: "",
    companyPage: "",
    developmentProcess: "",
    methodologies: "",
    companyImages: [],
    languages: "",
    workTags: "",
    idealClients: "",
    contactPersonName: "",
    contactPersonImage: null,
    phoneNumber: "",
    image: null,
    createdAt: new Date(),
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleFileChange = (e) => {
    const { name, files } = e.target;
    setFormData({ ...formData, [name]: files });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log("Form submitted:", formData);
    // Add form submission logic here
  };

  return (
    <>
      <Helmet>
        <title>Company Registration - Bharat Soft Supplier</title>
        <meta name="description" content="Company Registration" />
      </Helmet>
      <PageBanner
        title="Company Registration"
        bannerBg={BannerImg}
        currentPage="Company Registration"
      />
      <div className="container-fluid mt-3">
        <form onSubmit={handleSubmit}>
          <div className="row">
            <div className="col-md-6">
              <div className="form-group">
                <label>Company Name</label>
                <input
                  type="text"
                  name="companyName"
                  value={formData.companyName}
                  onChange={handleInputChange}
                  className="form-control"
                  placeholder="Enter the company name"
                  required
                />
              </div>
              <div className="form-group">
                <label>Average Project Size</label>
                <input
                  type="text"
                  name="avgProjectSize"
                  value={formData.avgProjectSize}
                  onChange={handleInputChange}
                  className="form-control"
                  placeholder="Enter the average project size"
                  required
                />
              </div>
              <div className="form-group">
                <label>Projects Completed</label>
                <input
                  type="text"
                  name="projectCompleted"
                  value={formData.projectCompleted}
                  onChange={handleInputChange}
                  className="form-control"
                  placeholder="Enter the number of projects completed"
                  required
                />
              </div>
              <div className="form-group">
                <label>Company Size</label>
                <input
                  type="text"
                  name="companySize"
                  value={formData.companySize}
                  onChange={handleInputChange}
                  className="form-control"
                  placeholder="Enter the company size"
                  required
                />
              </div>
              <div className="form-group">
                <label>Visit Website</label>
                <input
                  type="text"
                  name="visitWebsite"
                  value={formData.visitWebsite}
                  onChange={handleInputChange}
                  className="form-control"
                  placeholder="Enter the company website"
                  required
                />
              </div>
              <div className="form-group">
                <label>Location</label>
                <input
                  type="text"
                  name="location"
                  value={formData.location}
                  onChange={handleInputChange}
                  className="form-control"
                  placeholder="Enter the company location"
                  required
                />
              </div>
              <div className="form-group">
                <label>About Company</label>
                <textarea
                  name="aboutCompany"
                  value={formData.aboutCompany}
                  onChange={handleInputChange}
                  className="form-control"
                  placeholder="Describe the company"
                  required
                />
              </div>
              <div className="form-group">
                <label>Remote Collaboration Possible</label>
                <select
                  name="remoteCollaborationPossible"
                  value={formData.remoteCollaborationPossible}
                  onChange={handleInputChange}
                  className="form-control"
                  required
                >
                  <option value="">Select</option>
                  <option value="yes">Yes</option>
                  <option value="no">No</option>
                </select>
              </div>
              <div className="form-group">
                <label>Company Page</label>
                <input
                  type="text"
                  name="companyPage"
                  value={formData.companyPage}
                  onChange={handleInputChange}
                  className="form-control"
                  placeholder="Enter the company page URL"
                  required
                />
              </div>
              <div className="form-group">
                <label>Development Process</label>
                <input
                  type="text"
                  name="developmentProcess"
                  value={formData.developmentProcess}
                  onChange={handleInputChange}
                  className="form-control"
                  placeholder="Enter the development process"
                  required
                />
              </div>
            </div>
            <div className="col-md-6">
              <div className="form-group">
                <label>Methodologies (comma-separated)</label>
                <input
                  type="text"
                  name="methodologies"
                  value={formData.methodologies}
                  onChange={handleInputChange}
                  className="form-control"
                  placeholder="Enter the methodologies (comma-separated)"
                  required
                />
              </div>
              <div className="form-group">
                <label>Company Images</label>
                <input
                  type="file"
                  name="companyImages"
                  multiple
                  onChange={handleFileChange}
                  className="form-control"
                  required
                />
              </div>
              <div className="form-group">
                <label>Languages (comma-separated)</label>
                <input
                  type="text"
                  name="languages"
                  value={formData.languages}
                  onChange={handleInputChange}
                  className="form-control"
                  placeholder="Enter the languages (comma-separated)"
                  required
                />
              </div>
              <div className="form-group">
                <label>Work Tags (comma-separated)</label>
                <input
                  type="text"
                  name="workTags"
                  value={formData.workTags}
                  onChange={handleInputChange}
                  className="form-control"
                  placeholder="Enter work tags (comma-separated)"
                  required
                />
              </div>
              <div className="form-group">
                <label>Ideal Clients</label>
                <input
                  type="text"
                  name="idealClients"
                  value={formData.idealClients}
                  onChange={handleInputChange}
                  className="form-control"
                  placeholder="Enter the ideal clients"
                  required
                />
              </div>
              <div className="form-group">
                <label>Contact Person Name</label>
                <input
                  type="text"
                  name="contactPersonName"
                  value={formData.contactPersonName}
                  onChange={handleInputChange}
                  className="form-control"
                  placeholder="Enter the contact person's name"
                  required
                />
              </div>
              <div className="form-group">
                <label>Contact Person Image</label>
                <input
                  type="file"
                  name="contactPersonImage"
                  onChange={handleFileChange}
                  className="form-control"
                  required
                />
              </div>
              <div className="form-group">
                <label>Phone Number</label>
                <input
                  type="text"
                  name="phoneNumber"
                  value={formData.phoneNumber}
                  onChange={handleInputChange}
                  className="form-control"
                  placeholder="Enter the phone number"
                  required
                />
              </div>
              <div className="form-group">
                <label>Company Logo</label>
                <input
                  type="file"
                  name="image"
                  onChange={handleFileChange}
                  className="form-control"
                  required
                />
              </div>
            </div>
          </div>
          <button
            type="submit"
            className="btn btn-primary p-3 mt-3 mb-3 w-100"
            style={{ backgroundColor: "#14254C" }}
          >
            Submit
          </button>
        </form>
      </div>
    </>
  );
}
