import React from "react";
import { Link } from "react-router-dom";
import FooterCta from "./FooterCta";
import FooterOneCopyrights from "./FooterOneCopyrights";
import footerMap from "../../assets/img/footer-map.png";

const ScrollToTop = () => {
  window.scrollTo({
    top: 0,
    behavior: "smooth",
  });
};

const FooterTwo = () => {
  return (
    <footer className="footer-2 footer-wrap">
      <div className="footer-widgets-wrapper text-white">
        <div className="container">
          <div className="row">
            <div className="col-xl-3 pe-xl-0 col-sm-6 col-12">
              <div className="single-footer-wid site_info_widget">
                <div className="wid-title">
                  <h3>Get In Touch</h3>
                </div>
                <div className="contact-us">
                  <div className="single-contact-info">
                    <div className="icon">
                      <i className="fal fa-phone"></i>
                    </div>
                    <div className="contact-info">
                      <a href="tel:+31644806659">
                        <p>+31644806659</p>
                        {/* <p>908 9098 987 98</p> */}
                      </a>
                    </div>
                    <div className="contact-info">
                      <a href="tel:+31644905433">
                        <p> ,+31644905433</p>
                        {/* <p>908 9098 987 98</p> */}
                      </a>
                    </div>
                  </div>

                  <div className="single-contact-info">
                    <div className="icon">
                      <i className="fal fa-envelope"></i>
                    </div>
                    <div className="contact-info">
                      <a href="mailto:hallo@bellegirllifestyle.com">
                        <p>hallo@bellegirllifestyle.com</p>
                      </a>
                      {/* <p>info@webexample.com</p> */}
                    </div>
                  </div>
                  <div className="single-contact-info">
                    <div className="contact-info">
                      <p>
                        Klifrakplantsoen 178, 3544 RC Utrecht. The Netherlands
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-sm-6 offset-xl-1 col-xl-3 ps-xl-5 col-12">
              <div className="single-footer-wid">
                <div className="wid-title">
                  <h3>Quick Links</h3>
                </div>
                <ul>
                  <li>
                    <Link to="/" onClick={ScrollToTop}>
                      Home
                    </Link>
                  </li>
                  <li>
                    <Link to="/company-list" onClick={ScrollToTop}>
                      Company List
                    </Link>
                  </li>
                  <li>
                    <Link to="/company-registration" onClick={ScrollToTop}>
                      Company Registration
                    </Link>
                  </li>
                  <li>
                    <Link to="/blogs" onClick={ScrollToTop}>
                      Blogs
                    </Link>
                  </li>
                  <li>
                    <Link to="/about" onClick={ScrollToTop}>
                      About Us
                    </Link>
                  </li>
                </ul>
              </div>
            </div>

            <div className="col-sm-6 col-xl-4 offset-xl-1 col-12">
              <div className="single-footer-wid newsletter_widget">
                <div className="wid-title text-center">
                  <h3>Our Footprint</h3>
                </div>
                <div className="map-location">
                  <img src={footerMap} alt="" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <FooterCta />
      <FooterOneCopyrights />
    </footer>
  );
};

export default FooterTwo;
