import React from "react";
import { Link } from "react-router-dom";
// import footerLogo from '../../assets/img/footer-logo.png';
import Logo from "../Images/logo-white.png";
import WhiteLogo from "../Images/logoWhite.png";

import Img1 from "../Images/aatmnirbhar-bharat.png";
import Img2 from "../Images/iso9001.png";

const ScrollToTop = () => {
  window.scrollTo({
    top: 0,
    behavior: "smooth",
  });
};

const FooterCta = () => {
  return (
    <div className="footer-cta-wrapper">
      <div className="container">
        <div
          className="footer-cta-bg-wrapper"
          style={{ backgroundColor: "#D76D2C" }}
        >
          <div className="row justify-content-around align-items-center">
            <div className="col-lg-3 col-md-3 col-12">
              <div className="footer-logo">
                <Link to="/" onClick={ScrollToTop}>
                  <img src={WhiteLogo} alt="logo" height={100} width={350} />
                </Link>
              </div>
            </div>
            <div className="col-lg-4 col-md-5 ps-lg-0 offset-lg-1 col-12">
              <div className="footer-middle-text mt-4 mb-4 mt-md-0 mb-md-0 text-white">
                {/* <p>Where you see Innovation in Action! (TAGLINE)</p> */}
              </div>
            </div>
            <div className="col-md-4 col-lg-4 col-12">
              {/* <div className="footer-social-icon ms-md-5 text-lg-md-end">
                <a href="/">
                  <i className="fab fa-facebook-f"></i>
                </a>
                <a href="/">
                  <i className="fab fa-twitter"></i>
                </a>
                <a href="/">
                  <i className="fab fa-instagram"></i>
                </a>
                <a href="/">
                  <i className="fab fa-linkedin-in"></i>
                </a>
              </div> */}
              <div className="row" style={{ backgroundColor: "#D76D2C" }}>
                <div className="col-md-6">
                  <img src={Img1} alt="" height={150} width={150} />
                </div>
                <div className="col-md-6">
                  <img src={Img2} alt="" height={150} width={150} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FooterCta;
